import React from "react";

export const Sweets = () => {
  return (
    <div className="h-fit w-screen">
      <div className="mt-5 flex items-center justify-center text-lg font-bold text-yellow-800">
        Coming soon
      </div>
    </div>
  );
};

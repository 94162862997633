import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";
import Topbar from "./components/Topbar";
import { Intro } from "./pages/Intro";
import { Catering } from "./pages/Catering";
import { Size } from "./categories/catering/Size";
import { Beef } from "./categories/catering/Beef";
import { Pork } from "./categories/catering/Pork";
import { Chicken } from "./categories/catering/Chicken";
import { Pancit } from "./categories/catering/Pancit";
import { Fish } from "./categories/catering/Fish";
import { Veggies } from "./categories/catering/Veggies";
import { Sweets } from "./categories/catering/Sweets";
import "aos/dist/aos.css";
import { Contact } from "./pages/Contact";
import { Dine } from "./pages/Dine";
import { Cabinet } from "./categories/dine/Cabinet";
import { Appetizer } from "./categories/dine/Appetizer";
import { Grill } from "./categories/dine/Grill";
import { Mains } from "./categories/dine/Mains";
import { Silog } from "./categories/dine/Silog";
import { Vegetarian } from "./categories/dine/Vegetarian";
import { Soup } from "./categories/dine/Soup";
import { Favourites } from "./categories/dine/Favourites";
import { Noodles } from "./categories/dine/Noodles";
import { Fusion } from "./categories/dine/Fusion";
import { Dessert } from "./categories/dine/Dessert";
import { FTappetizer } from "./categories/truck/FTappetizer";
import { FTmains } from "./categories/truck/FTmains";
import { FTgrill } from "./categories/truck/FTgrill";
import { FTsilog } from "./categories/truck/FTsilog";
import { FTruck } from "./pages/FTruck";
import { FTinfo } from "./categories/truck/FTinfo";
import BookingsDine from "./components/BookingDine";
import { ErrorPage } from "./pages/ErrorPage";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Topbar />
      <Routes>
        <Route exact path="/" element={<Intro />} />
        <Route path="dine" element={<Dine />}>
          <Route path="cabinet" element={<Cabinet />} />
          <Route path="appetizer" element={<Appetizer />} />
          <Route path="grill" element={<Grill />} />
          <Route path="mains" element={<Mains />} />
          <Route path="silog" element={<Silog />} />
          <Route path="vegetarian" element={<Vegetarian />} />
          <Route path="soup" element={<Soup />} />
          <Route path="favourites" element={<Favourites />} />
          <Route path="noodles" element={<Noodles />} />
          <Route path="fusion" element={<Fusion />} />
          <Route path="dessert" element={<Dessert />} />
        </Route>
        <Route path="catering" element={<Catering />}>
          <Route path="size" element={<Size />} />
          <Route path="beef" element={<Beef />} />
          <Route path="pork" element={<Pork />} />
          <Route path="chicken" element={<Chicken />} />
          <Route path="pancit" element={<Pancit />} />
          <Route path="fish" element={<Fish />} />
          <Route path="veggies" element={<Veggies />} />
          <Route path="sweets" element={<Sweets />} />
        </Route>
        <Route path="FTruck" element={<FTruck />}>
          <Route path="FTinfo" element={<FTinfo />} />
          <Route path="FTappetizer" element={<FTappetizer />} />
          <Route path="FTmains" element={<FTmains />} />
          <Route path="FTsilog" element={<FTsilog />} />
          <Route path="FTgrill" element={<FTgrill />} />
        </Route>
        <Route path="contact" element={<Contact />} />
        <Route path="reservations" element={<BookingsDine />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;

import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { FaCheckCircle } from "react-icons/fa";

export const Contact = () => {
  const form = useRef();
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm();

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_g3t5cq7",
        "template_45duuwp",
        form.current,
        "5Bxi5SCHBs8DmDu7l"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message sent!");

          setStatusMsg("Message sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );
    reset();
  };

  const [statusMsg, setStatusMsg] = useState("");

  useEffect(() => {
    if (statusMsg === "Message sent!") {
      setTimeout(() => {
        setStatusMsg("");
      }, 3000);
    }
  }, [statusMsg]);

  const renderAlert = () => (
    <div className="absolute z-50 mb-5 flex h-full w-full items-center justify-center rounded text-center leading-normal">
      <div className=" flex h-1/2 w-1/2 flex-col items-center justify-center space-y-4 rounded-3xl bg-black/80 opacity-80">
        <FaCheckCircle className="text-3xl text-green-500" />
        <p className="text-white">Message sent!</p>
      </div>
    </div>
  );

  const facebook = () => {
    window.open("https://www.facebook.com/MabuhayBBQandGrill");
  };

  return (
    <div className="h-screen w-screen overflow-y-auto bg-gradient-primary font-primary">
      <div className="relative top-28 mx-auto w-3/4 pb-12 md:w-1/2 lg:w-2/5 2xl:top-52 2xl:w-4/12">
        {statusMsg && renderAlert()}
        <h2 class="mb-4 text-center text-3xl font-extrabold tracking-tight text-yellowed 2xl:mb-6 2xl:text-4xl">
          Get in touch
        </h2>
        <p className="mb-8 text-center text-sm font-light tracking-wider text-white lg:mb-12 2xl:text-xl">
          For business inquiries, feedback, or suggestions send us a message
          through the form below.
        </p>
        <form
          ref={form}
          onSubmit={handleSubmit(sendEmail)}
          className="space-y-8"
        >
          <div>
            <input
              type="text"
              id="name"
              name="user_name"
              className="block w-full rounded-lg border border-yellowed bg-yellowed/10 p-3 text-sm text-white shadow-sm focus:border-amber-800 2xl:text-2xl"
              placeholder="Name"
              {...register("user_name", { required: "Name is required" })}
              onKeyUp={() => {
                trigger("user_name");
              }}
            />
            {errors.user_name && (
              <small className="mb-1 ml-2 text-xs text-red-500 2xl:text-sm">
                {errors.user_name.message}
              </small>
            )}
          </div>
          <div>
            <input
              type="email"
              id="email"
              name="user_email"
              className="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-lg border border-yellowed bg-yellowed/10 p-3 text-sm text-white shadow-sm 2xl:text-2xl"
              placeholder="E-mail"
              {...register("user_email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              onKeyUp={() => {
                trigger("user_email");
              }}
            />
            {errors.user_email && (
              <small className="mb-1 ml-2 text-xs text-red-500 2xl:text-sm">
                {errors.user_email.message}
              </small>
            )}
          </div>
          <div className="sm:col-span-2">
            <textarea
              id="message"
              name="message"
              rows="6"
              className="focus:ring-primary-500 focus:border-primary-500 block w-full resize-none rounded-lg border border-yellowed bg-yellowed/10 p-2.5 text-sm text-white shadow-sm 2xl:text-2xl"
              placeholder="Let us know how we can help you"
              {...register("message", {
                required: "Please tell us how we can help you.",
                minLength: {
                  value: 50,
                  message: "Please write a meaningful message.",
                },
              })}
              onKeyUp={() => {
                trigger("message");
              }}
            />
            {errors.message && (
              <small className="mb-3 ml-2 text-xs text-red-500 2xl:text-sm">
                {errors.message.message}
              </small>
            )}
          </div>
          <div className="flex w-full flex-col items-center justify-center gap-y-6 2xl:gap-y-8">
            <button
              type="submit"
              className="hover:bg-primary-800 rounded-lg bg-amber-700 py-3 px-5 text-center text-sm font-normal tracking-wider text-white hover:bg-amber-600 focus:outline-none sm:w-fit 2xl:text-xl"
            >
              Send message
            </button>
            <p className="w-fit text-center text-sm font-thin leading-6 text-white 2xl:text-xl">
              *For dine-in and catering inquiries, please contact us on
              <span
                onClick={facebook}
                className="mx-2 cursor-pointer text-yellowed hover:underline"
              >
                facebook
              </span>
              <br />
              or call 07-981-1221.
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

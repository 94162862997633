import { useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { NavLink, Outlet } from "react-router-dom";

export const DineMobileNav = ({ data }) => {
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);

  const menuRef = useRef();

  window.addEventListener("click", (e) => {
    if (e.target !== menuRef.current) setOpen(false);
  });

  return (
    <div className="flex w-screen flex-col items-center justify-center">
      <div className="mx-8 h-80 w-64 font-medium">
        <div
          ref={menuRef}
          onClick={() => setOpen(!open)}
          className={`relative flex w-full items-center justify-between rounded border border-yellowed bg-yellowed/10 p-2 text-white backdrop-blur-xl ${
            !selected && "text-white/60"
          }`}
        >
          {selected
            ? selected?.length > 25
              ? selected?.substring(0, 25) + "..."
              : selected
            : "Dine menu"}
          <BiChevronDown size={20} className={`${open && "rotate-180"}`} />
        </div>

        <div
          className={`z-50 mt-2 overflow-y-auto rounded-t-sm rounded-b-lg bg-gray-300 ${
            open ? "absolute max-h-60 w-64" : "max-h-0"
          } `}
        >
          {data?.map((data) => (
            <NavLink
              to={data.url}
              key={data?.label}
              className={`flex flex-col items-center justify-center p-4 text-sm tracking-widest text-gray-800 hover:bg-yellow-800 hover:text-white
            ${data?.label === selected && "font-bold"}
            ${data?.label.startsWith(inputValue) ? "block" : "hidden"}`}
              onClick={() => {
                if (data?.label !== selected) {
                  setSelected(data?.label);
                  setOpen(false);
                  setInputValue("");
                }
              }}
            >
              {data?.label}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="-mt-60 w-full">
        <Outlet />
      </div>
    </div>
  );
};

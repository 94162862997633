import Typewriter from "typewriter-effect";

export const TypingEffect = () => {
  return (
    <div className="w-3/4 font-primary text-3xl font-normal tracking-wider md:text-4xl 2xl:text-6xl">
      <Typewriter
        options={{
          strings: ["Authentic filipino cuisine", "BBQ", "Native delicacies"],
          autoStart: true,
          delay: 75,
          loop: true,
        }}
      />
    </div>
  );
};

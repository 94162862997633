import Helmet from "react-helmet";
import { NavLink, Outlet } from "react-router-dom";
import { CaterMobileNav } from "../components/CaterMobileNav";

export const Catering = () => {
  const data = [
    {
      label: "Size",
      url: "size",
    },
    {
      label: "Beef",
      url: "beef",
    },
    {
      label: "Pork",
      url: "pork",
    },
    {
      label: "Chicken",
      url: "chicken",
    },
    {
      label: "Pancit",
      url: "pancit",
    },
    {
      label: "Fish",
      url: "fish",
    },
    {
      label: "Veggies",
      url: "veggies",
    },
    {
      label: "Dessert",
      url: "sweets",
    },
  ];

  const navLinkStyles = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#f59e0b" : "",
    };
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />

        <meta
          name="description"
          content="We cater Filipino party food trays perfect for groups of any size, from intimate gatherings to small-medium events."
        />
        <meta
          name="keywords"
          content="mabuhay bbq and grill catering menu, mabuhay bbq and grill catering menu and price, filipino food catering hamilton, filipino food catering menu, best filipino catering hamilton, asian food catering hamilton, filipino party food trays hamilton, cheap filipino food trays hamilton, cheap filipino catering hamilton, asian catering, filipino catering hamilton"
        />
      </Helmet>
      <div className="h-screen overflow-y-auto bg-gradient-primary">
        <div className="relative top-28 hidden w-screen flex-col items-center justify-center md:flex 2xl:top-52">
          <div className="hidden w-full flex-row justify-center space-x-5 border-b border-dotted border-yellowed md:flex 2xl:space-x-8">
            {data.map((data) => (
              <NavLink
                key={data}
                to={data.url}
                style={navLinkStyles}
                className="inline-block rounded-t-lg p-3 tracking-wider text-white no-underline 2xl:text-2xl"
              >
                {data.label}
              </NavLink>
            ))}
          </div>
          <Outlet />
        </div>

        {/* catering nav for mobile screens */}
        <div className="relative top-32 flex w-screen justify-center md:hidden">
          <CaterMobileNav data={data} />
        </div>
      </div>
    </>
  );
};

import Helmet from "react-helmet";
import { NavLink, Outlet } from "react-router-dom";
import { TruckMobileNav } from "../components/TruckMobileNav";

export const FTruck = () => {
  const data = [
    {
      label: "Food truck",
      url: "FTinfo",
    },

    {
      label: "Mains",
      url: "FTmains",
    },

    {
      label: "Grill",
      url: "FTgrill",
    },
  ];

  const navLinkStyles = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#f59e0b" : "",
    };
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="keywords"
          content="chicken and pork adobo rice topping, caldereta rice topping, bicol express rice topping, chicken inasal rice topping, bbq rice topping, food truck, filipino cuisine "
        />
        <meta
          name="description"
          content="Filipino food truck in hamilton offering rice toppings and grilled bbq takeaways."
        />
      </Helmet>
      <div className="h-screen overflow-y-auto bg-gradient-primary">
        <div className="relative top-28 hidden w-screen flex-col items-center justify-center md:flex 2xl:top-52">
          <div className="hidden w-full flex-row justify-center space-x-8 border-b border-dotted border-yellowed md:flex 2xl:space-x-8">
            {data.map((data) => (
              <NavLink
                key={data}
                to={data.url}
                style={navLinkStyles}
                className="inline-block rounded-t-lg p-3 tracking-wider text-white no-underline 2xl:text-2xl"
              >
                {data.label}
              </NavLink>
            ))}
          </div>
          <Outlet />
        </div>

        {/* catering nav for mobile screens */}
        <div className="relative top-32 flex w-screen justify-center md:hidden">
          <TruckMobileNav data={data} />
        </div>
      </div>
    </>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const navigate = useNavigate();

  const navigateHomepage = () => {
    navigate("/");
  };
  return (
    <div className="h-screen w-screen overflow-y-auto bg-gradient-primary font-primary">
      <div className="relative top-32 mx-auto w-3/4 pb-12 md:w-4/6 lg:w-1/2">
        <div className="space-y-5 rounded-lg bg-yellowed/10 px-4 py-12 shadow-lg">
          <h1 className="text-center text-3xl font-extrabold tracking-tight text-yellow-700">
            404
          </h1>
          <h2 className="text-md text-center font-medium tracking-tight text-yellow-700">
            oops! This page doesn't exist
          </h2>

          <div className="flex w-full items-center justify-center">
            <button
              onClick={navigateHomepage}
              type="button"
              className="rounded-full bg-yellow-800 px-8 py-4 font-medium tracking-wider text-white hover:bg-yellow-900"
            >
              Back to homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const Footer = () => {
  return (
    <div className="flex w-screen flex-col items-center justify-center gap-6 bg-gradient-primary pb-8 text-white xl:pb-10">
      <div className="w-1/2 rounded-xl border border-yellow-500/5"></div>
      <p className="text-xs">&copy; 2024 All rights reserved.</p>
    </div>
  );
};

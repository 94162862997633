import foodtruck from "../../assets/daymarket.jpg";
import CircleLoader from "react-spinners/CircleLoader";
import { useState } from "react";

export const FTinfo = () => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };
  return (
    <div className="grid w-screen md:grid-cols-2 md:gap-4 md:px-4 md:py-8 lg:h-fit lg:gap-6 lg:px-8 2xl:mt-4">
      {/* Text */}
      <div className="flex w-screen flex-col items-center justify-center gap-8 pb-8 font-primary text-sm font-thin leading-relaxed tracking-wider text-white md:w-full md:rounded-xl md:border-4 md:border-double md:border-yellowed">
        {/* Info */}
        <div className="w-4/5 md:mt-6">
          <h1 className="text-lg font-medium uppercase text-yellowed md:text-xl 2xl:text-3xl">
            Food truck
          </h1>
          <p className="mt-2 mb-5 2xl:mt-4 2xl:text-lg">
            Explore Filipino culinary delights at 'Mabuhay BBQ & Grill's food
            truck in Hamilton.
          </p>
          <ul className="list-disc space-y-4 px-6 2xl:text-lg">
            <li>
              Stop by our stall at the Hamilton Night Market every Friday from 5
              pm to 8pm.
            </li>
            <li>
              Location: Wilson Carpark, 306 Barton Street, Hamilton Central.
            </li>
          </ul>
        </div>
      </div>

      {/* Image */}
      <div className="w-screen  md:aspect-w-5 md:aspect-h-3 md:w-full">
        {loading && (
          <div className="flex h-full items-center justify-center">
            <CircleLoader
              loading={loading}
              size={40}
              color="#fcd116"
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <img
          src={foodtruck}
          alt=""
          className=" object-cover shadow-lg md:rounded-xl"
          onLoad={handleImageLoad}
        />
      </div>
    </div>
  );
};

import { Helmet } from "react-helmet";

export default function BookingsDine() {
  return (
    <>
      <Helmet>
        <script
          src="https://www.fbgcdn.com/embedder/js/ewm2.js"
          defer
          async
        ></script>
      </Helmet>
      <div className="h-screen w-screen overflow-y-auto bg-gradient-primary font-primary">
        <div className="relative top-28 mx-auto w-3/4 pb-12 md:w-4/6 lg:top-36 lg:w-1/2 2xl:top-60 2xl:w-2/5">
          <div className="space-y-10 rounded-lg bg-yellowed/10 py-12 shadow-lg 2xl:space-y-14 2xl:px-10 2xl:py-20">
            <h2 className="text-center text-3xl font-extrabold tracking-tight text-yellow-700 2xl:text-4xl">
              Reserve a table
            </h2>
            <ul className="list-disc space-y-5 px-12 font-primary text-sm font-thin leading-6 tracking-wider text-white 2xl:text-xl">
              <li>Reservation is necessary for large groups.</li>

              <li className="leading-6 tracking-wider">
                Please arrive on time. Reservation held for 15mins. Failure to
                arrive or inform us will result in a no-show or cancellation to
                accommodate walk-in guests.
              </li>
            </ul>
            <div className="flex w-full items-center justify-center">
              <button
                className="rounded-full bg-yellow-800 px-10 py-4 font-medium tracking-wider text-white hover:bg-yellow-900 2xl:text-lg"
                data-glf-cuid="a4cf80e2-f409-438b-9be3-fbf3fe51b7ba"
                data-glf-ruid="e15991c3-00c5-4735-82a1-568f383dbae7"
                data-glf-reservation="true"
              >
                Book now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// import { useState, useEffect } from "react";
// import { db } from "../../firebase-config";
// import { collection, onSnapshot } from "firebase/firestore";
// import CircleLoader from "react-spinners/CircleLoader";

// export const FTgrill = () => {
//   const [FTgrill, setFTgrill] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Listen REAL-TIME
//     const getFTgrill = onSnapshot(
//       collection(db, "FTgrill"),
//       (snapShot) => {
//         let list = [];
//         snapShot.docs.forEach((doc) => {
//           list.push({ id: doc.id, ...doc.data() });
//         });
//         setFTgrill(list);
//         setLoading(false); // Set loading to false when data is fetched
//       },
//       (error) => {
//         console.log(error);
//       }
//     );

//     return () => {
//       getFTgrill();
//     };
//   }, []);

//   return (
//     <div className="mx-2 mb-6 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 md:my-8 md:mx-4 lg:mx-6 lg:grid-cols-4 xl:mx-12">
//       {loading ? (
//         <div className="mt-12 flex w-screen items-center justify-center">
//           <CircleLoader
//             loading={loading}
//             size={40}
//             color="#fcd116"
//             aria-label="Loading Spinner"
//             data-testid="loader"
//           />
//         </div>
//       ) : (
//         FTgrill.map((FTgrill) => (
//           <div
//             key={FTgrill.id}
//             className="relative h-fit rounded-lg bg-yellowed/10 font-primary tracking-wide shadow-lg 2xl:mt-4"
//           >
//             <div className="xs:aspect-h-4 aspect-w-5 aspect-h-5 md:aspect-h-4 lg:aspect-w-6 lg:aspect-h-4 2xl:aspect-w-7">
//               <img
//                 src={FTgrill.image}
//                 alt=""
//                 className="rounded-t-lg object-cover shadow-lg"
//               />
//             </div>

//             <div className="h-[16rem] p-4 md:h-[8rem] 2xl:h-[12rem] 2xl:p-6">
//               <div className="space-y-2 2xl:space-y-4">
//                 <h5 className="text-sm font-medium text-yellowed 2xl:text-lg">
//                   {FTgrill.name}
//                 </h5>
//                 <p className="text-xs font-thin leading-relaxed text-white 2xl:text-lg">
//                   {FTgrill.description}
//                 </p>
//               </div>
//             </div>
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

import React from "react";

export const FTgrill = () => {
  return (
    <div className="h-fit w-screen">
      <div className="mt-5 flex items-center justify-center text-lg font-bold text-yellow-800">
        Coming soon
      </div>
    </div>
  );
};

import Helmet from "react-helmet";
import { NavLink, Outlet } from "react-router-dom";
import { DineMobileNav } from "../components/DineMobileNav";

export const Dine = () => {
  const data = [
    {
      label: "Cabinet",
      url: "cabinet",
    },
    {
      label: "Appetizer",
      url: "appetizer",
    },
    {
      label: "Grill",
      url: "grill",
    },
    {
      label: "Mains",
      url: "mains",
    },
    {
      label: "Silog",
      url: "silog",
    },
    {
      label: "Vegetarian",
      url: "vegetarian",
    },
    {
      label: "Soup",
      url: "soup",
    },
    {
      label: "Favourites",
      url: "favourites",
    },
    {
      label: "Noodles",
      url: "noodles",
    },

    {
      label: "Dessert",
      url: "dessert",
    },
  ];

  const navLinkStyles = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#f59e0b" : "",
    };
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />

        <meta
          name="description"
          content="Enjoy Filipino favourites such as classic adobo, sisig, crispy pata, chicken inasal, and halo-halo, available for dine-in, takeaway, and UberEats delivery."
        />
        <meta
          name="keywords"
          content="mabuhay bbq and grill menu, mabuhay bbq and grill menu hamilton, filipino menu hamilton, filipino food hamilton menu, mabuhay bbq and grill catering size"
        />
      </Helmet>
      <div className="h-screen overflow-y-auto rounded-none bg-gradient-primary">
        <div className="relative top-28 hidden w-screen flex-col items-center justify-center md:flex 2xl:top-52">
          <div className="hidden w-full border-b border-dotted border-yellowed md:flex md:justify-center">
            <div className="mx-8 flex w-full flex-row space-x-4 overflow-x-auto lg:justify-center 2xl:w-fit">
              {data.map((data) => (
                <NavLink
                  key={data}
                  to={data.url}
                  style={navLinkStyles}
                  className="inline-block rounded-t-lg p-3 font-medium tracking-wider text-white no-underline 2xl:text-2xl"
                >
                  {data.label}
                </NavLink>
              ))}
            </div>
          </div>
          <Outlet />
        </div>

        {/* catering nav for mobile screens */}
        <div className="relative top-32 flex w-screen justify-center md:hidden">
          <DineMobileNav data={data} />
        </div>
      </div>
    </>
  );
};

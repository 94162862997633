import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { FiAlignLeft, FiX } from "react-icons/fi";
import Logo from "../assets/logoround.png";

export default function Topbar() {
  const [openNav, setOpenNav] = useState(false);

  const handleLogoClick = () => {
    setOpenNav(false);
  };

  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#fcd116" : "",
    };
  };

  const navigate = useNavigate();

  const navigateBookings = () => {
    setOpenNav(false);
    navigate("/reservations");
  };

  const navList = (
    <ul className="my-4 flex flex-col items-center gap-8 py-16 text-white 2xl:gap-16 2xl:py-28">
      <Typography
        as="li"
        className="p-1 font-primary font-normal tracking-wider hover:underline hover:decoration-yellowed hover:decoration-2 hover:underline-offset-8"
      >
        <NavLink
          to="dine/cabinet"
          style={navLinkStyles}
          className="flex items-center text-base 2xl:text-3xl"
        >
          Dine
        </NavLink>
      </Typography>
      <Typography
        as="li"
        className="p-1 font-primary font-normal tracking-wider hover:underline hover:decoration-yellowed hover:decoration-2 hover:underline-offset-8"
      >
        <NavLink
          to="catering/size"
          style={navLinkStyles}
          className="flex items-center text-base 2xl:text-3xl"
        >
          Catering
        </NavLink>
      </Typography>
      <Typography
        as="li"
        className="p-1 font-primary font-normal tracking-wider hover:underline hover:decoration-yellowed hover:decoration-2 hover:underline-offset-8"
      >
        <NavLink
          to="/FTruck/FTinfo"
          style={navLinkStyles}
          className="flex items-center text-base 2xl:text-3xl"
        >
          Food truck
        </NavLink>
      </Typography>
      <Typography
        as="li"
        className="p-1 font-primary font-normal tracking-wider hover:underline hover:decoration-yellowed hover:decoration-2 hover:underline-offset-8"
      >
        <NavLink
          to="/contact"
          style={navLinkStyles}
          className="flex items-center text-base 2xl:text-3xl"
        >
          Contact
        </NavLink>
      </Typography>
    </ul>
  );

  return (
    <Navbar
      className={
        openNav
          ? "fixed z-50 mx-auto max-w-full rounded-none border-none bg-black/40 py-3 backdrop-blur-md lg:px-28 2xl:px-48 2xl:py-6"
          : "fixed z-50 mx-auto max-w-full rounded-none border-none bg-black/40 py-3 backdrop-blur-sm lg:px-28 2xl:px-48 2xl:py-6"
      }
    >
      <div className="mx-auto flex justify-between text-white">
        <Link
          to="/"
          className="flex flex-row items-center  font-primary tracking-wider md:gap-5 2xl:-ml-10 2xl:gap-8"
          onClick={handleLogoClick}
        >
          <img
            src={Logo}
            alt="Mabuhay BBQ and Grill Logo"
            className="h-10 w-auto rounded-full bg-white object-contain 2xl:h-20"
          />
          <p className="hidden text-sm text-white md:flex 2xl:text-2xl">
            Mabuhay BBQ & Grill
          </p>
        </Link>

        <div className="flex w-56 items-center justify-end gap-12 px-4 md:w-72 md:gap-16 2xl:w-96 2xl:gap-24">
          <Button
            onClick={navigateBookings}
            type="button"
            className="border-2 border-double border-yellowed bg-transparent px-4 font-primary normal-case tracking-wide text-white shadow-none hover:shadow-none md:px-5 md:py-2 md:text-sm lg:inline-block 2xl:px-10 2xl:py-4 2xl:text-2xl"
          >
            <span>Book a table</span>
          </Button>

          <IconButton
            variant="text"
            className="h-6 w-2 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <FiX className="text-3xl 2xl:text-5xl" />
            ) : (
              <FiAlignLeft className="text-3xl 2xl:text-5xl" />
            )}
          </IconButton>
        </div>
      </div>
      <MobileNav open={openNav}>
        <div className="container mx-auto" onClick={() => setOpenNav(!openNav)}>
          {navList}
        </div>
      </MobileNav>
    </Navbar>
  );
}

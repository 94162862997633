import { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { collection, onSnapshot } from "firebase/firestore";
import CircleLoader from "react-spinners/CircleLoader";

export const Fish = () => {
  const [fish, setFish] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen REAL-TIME
    const getFish = onSnapshot(
      collection(db, "fish"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setFish(list);
        setLoading(false); // Set loading to false when data is fetched
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      getFish();
    };
  }, []);

  return (
    <div className="mx-2 mb-6 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 md:my-8 md:mx-4 lg:mx-6 lg:grid-cols-4 xl:mx-12">
      {loading ? (
        <div className="mt-12 flex w-screen items-center justify-center">
          <CircleLoader
            loading={loading}
            size={40}
            color="#fcd116"
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        fish.map((fish) => (
          <div
            key={fish.id}
            className="relative h-fit rounded-lg bg-yellowed/10 font-primary tracking-wide shadow-lg 2xl:mt-4"
          >
            <div className="xs:aspect-h-4 aspect-w-5 aspect-h-5 md:aspect-h-4 lg:aspect-w-6 lg:aspect-h-4 2xl:aspect-w-7">
              <img
                src={fish.image}
                alt=""
                className="rounded-t-lg object-cover shadow-lg"
              />
            </div>

            <div className="h-[16rem] p-4 md:h-[13.5rem] lg:h-[12.5rem] 2xl:h-[16.5rem] 2xl:p-6">
              <div className="space-y-2 2xl:space-y-4">
                <h5 className="text-sm font-medium text-yellowed 2xl:text-lg">
                  {fish.name}
                </h5>
                <p className="text-xs font-thin leading-relaxed text-white 2xl:text-lg">
                  {fish.description}
                </p>
              </div>
              <div className="absolute bottom-0 space-y-3 py-3 text-xs font-thin tracking-widest text-yellowed 2xl:text-base">
                <h6>{fish.price}</h6>
                <h6>{fish.price2}</h6>
                <h6>{fish.price3}</h6>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

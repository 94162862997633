import catering from "../../assets/cater3.jpeg";
import CircleLoader from "react-spinners/CircleLoader";
import { useState } from "react";
import { Helmet } from "react-helmet";

export const Size = () => {
  const facebook = () => {
    window.open("https://www.facebook.com/MabuhayBBQandGrill");
  };

  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <script
          src="https://www.fbgcdn.com/embedder/js/ewm2.js"
          defer
          async
        ></script>
      </Helmet>
      <div className="grid w-screen md:grid-cols-2 md:gap-4 md:px-4 md:py-8 lg:h-fit lg:gap-6 lg:px-8 2xl:mt-4">
        {/* Text */}
        <div className="flex w-screen flex-col items-center justify-center gap-10 font-primary text-sm font-thin leading-relaxed tracking-wider text-white md:w-full md:rounded-xl md:border-4 md:border-double md:border-yellowed md:py-12 2xl:py-52">
          {/* Info */}
          <div className="w-4/5">
            <h1 className="text-lg font-medium uppercase text-yellowed md:text-xl 2xl:text-3xl">
              Party Trays
            </h1>
            <p className="mt-2 mb-5 2xl:mt-4 2xl:text-lg">
              We offer hassle-free and delicious food choices ready to eat and
              enjoy.
            </p>
            <ul className="list-disc space-y-4 px-6 2xl:text-lg">
              <li>Book at least 3 days in advance.</li>
              <li>No waitstaff, no delivery. Pick up only.</li>
              <li>
                You may choose your container. Chafing dish or foil tray. Please
                note: chafing dish must be returned after use.
              </li>
              <li>
                Contact us on
                <span
                  onClick={facebook}
                  className="mx-2 cursor-pointer text-yellowed 2xl:text-lg"
                >
                  facebook
                </span>
                for inquiries
              </li>
            </ul>
          </div>

          {/* Sizes */}
          <div className="w-4/5 space-y-4">
            <h2 className="text-md text-yellowed underline underline-offset-4 2xl:text-xl">
              APPROXIMATE SERVING SIZES
            </h2>
            <ul className="list-disc space-y-4 px-6 2xl:mt-4 2xl:text-lg">
              <li>Small: 10-15pax</li>
              <li>Medium: 20-25pax</li>
              <li>Large: 30-40pax</li>
            </ul>
          </div>
        </div>

        {/* Image */}
        <div className="mt-12 w-screen md:aspect-w-5 md:aspect-h-3 md:mt-0 md:w-full">
          {loading && (
            <div className="flex h-full items-center justify-center">
              <CircleLoader
                loading={loading}
                size={40}
                color="#fcd116"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          <img
            src={catering}
            alt="catering trays"
            className="object-cover"
            onLoad={handleImageLoad}
          />
        </div>
      </div>
    </>
  );
};

import { useState } from "react";
import cabinet from "../../assets/cabineth.jpeg";
import CircleLoader from "react-spinners/CircleLoader";
import Helmet from "react-helmet";

export const Cabinet = () => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Enjoy our turo-turo style, where you choose from 6 ready-to-serve dishes. Available for dine-in and takeaway."
        />
        <meta
          name="keywords"
          content="filipino turo turo hamilton, best filipino tuto turo hamilton"
        />
      </Helmet>
      <div className="grid w-screen md:grid-cols-2 md:gap-4 md:px-4 md:py-8 lg:h-fit lg:gap-6 lg:px-8 2xl:mt-4">
        {/* Text */}
        <div className="flex w-screen flex-col items-center justify-center gap-8 pb-8 font-primary text-sm font-thin leading-relaxed tracking-wider text-white md:w-full md:rounded-xl md:border-4 md:border-double md:border-yellowed">
          {/* Info */}
          <div className="w-4/5 md:mt-6">
            <h1 className="text-lg font-medium uppercase text-yellowed md:text-xl 2xl:text-3xl">
              Cabinet
            </h1>
            <p className="mt-2 mb-5 2xl:mt-4 2xl:mb-8 2xl:text-lg">
              Available for short orders is our turo-turo style option with 6
              different dishes to choose from.
            </p>
            <ul className="list-disc space-y-4 px-6 2xl:text-lg">
              <li>Available for dine-in and takeaway.</li>
              <li>Call 07-981-1221 for pick-up order and inquiries.</li>
            </ul>
          </div>
        </div>

        {/* Image */}
        <div className="w-screen  md:aspect-w-5 md:aspect-h-3 md:w-full">
          {loading && (
            <div className="flex h-full items-center justify-center">
              <CircleLoader
                loading={loading}
                size={40}
                color="#fcd116"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          <img
            src={cabinet}
            title="Filipino ulam available at Mabuhay BBQ & Grill Hamilton"
            alt="cabinet menu"
            className="object-cover"
            onLoad={handleImageLoad}
          />
        </div>
      </div>
    </>
  );
};
